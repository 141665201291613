
export default {
  name: 'HeroTiled',
  props: [
    'src',
    'alt',
    'pretitle',
    'title',
    'words_fixed_text',
    'words',
    'hero_tiled_images',
    'subtitle',
    'position',
    'positionModile',
    'video',
    'videos',
    'anchor',
    'maxwidth',
    'lightTheme',
    'noOverlay',
    'whiteOverlay',
    'ctatext',
    'ctalink',
    'ctavideo',
    'slides',
    'html',
    'heroClass',
    'heroContentClass',
    'isIndex',
  ]
}
